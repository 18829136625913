import React, { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl"
import Modal from "@mui/material/Modal"
import Popover from "@mui/material/Popover"
import Slider from "@mui/material/Slider"
import { visuallyHidden } from "@mui/utils";
import useSWR from "swr";
import API, { apiRootUrl } from "../../shared/API";

import "./HistoryTable.css";
import { STORED_TOKEN } from "../AdminContainer";
import PageTitleDescription from "../../shared/PageTitleDescription";
import { useLocation } from "react-router-dom";
import {
  convertDateToSliderPerc,
  convertSliderPercentToDate,
} from "./dateToPercentConversions";
import RenderedMessage from "../../components/RenderedMessage";

function HistoryTable() {
  const queryParams = new URLSearchParams(useLocation().search);

  const minimumDate = new Date("2022");
  const maximumDate = new Date();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datetime");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dense, setDense] = useState(true);

  // Ranges represent 0-100 on a slider and are converted via convertSliderPercentToDate func
  const [dateRange, setDateRange] = useState([
    queryParams.get("min_date")
      ? convertDateToSliderPerc(
          queryParams.get("min_date"),
          minimumDate,
          maximumDate
        )
      : 0,
    queryParams.get("max_date")
      ? convertDateToSliderPerc(
          queryParams.get("max_date"),
          minimumDate,
          maximumDate
        )
      : 100,
  ]);

  const [confRange, setConfRange] = useState([
    parseInt(queryParams.get("min_confidence")) || 0,
    parseInt(queryParams.get("max_confidence")) || 100,
  ]);
  const [errMessage, setErrMessage] = useState("");

  let historyUrl = `/history?page=${page}&items_per_page=${rowsPerPage}&order_by=${orderBy}&order=${order}`;
  historyUrl += `&min_conf=${confRange[0] / 100}&max_conf=${
    confRange[1] / 100
  }`;
  historyUrl += `&min_date=${convertSliderPercentToDate(
    minimumDate,
    dateRange[0],
    maximumDate
  ).toDateString()}&max_date=${convertSliderPercentToDate(
    minimumDate,
    dateRange[1],
    maximumDate
  ).toDateString()}`;

  const obj = useSWR(historyUrl, API);
  const { data: intents, error: intentsErr } = useSWR("/intents", API);
  const { data: history_, error: historyErr, mutate: historyMutate } = obj;
  const [history, historyCount] = useMemo(() => {
    if (history_ !== undefined) {
      return [history_.items, history_.count];
    } else {
      return [[], 0];
    }
  }, [history_]);

  if (historyErr || intentsErr || (history && history.error))
    return (
      <div>
        Failed to load. Your JWT may have expired. You may need to log out and
        then log back in again.
      </div>
    );
  if (!history || !intents) return <div>Loading.</div>;

  // TODO: Reinstate Confidence and Date Range Filters.

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addUtteranceToIntent = async (intent, utterance) => {
    const resp = await fetch(`${apiRootUrl}/intent/${intent}/utterance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
      },
      body: JSON.stringify({
        utterance,
      }),
    });
    if (resp.status === 200) {
      await historyMutate();
    } else {
      const body = await resp.json();
      setErrMessage(body.error);
    }
  };

  const delUtteranceFromIntent = async (utterance) => {
    const resp = await fetch(`${apiRootUrl}/utterance/${utterance}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
      },
    });
    if (resp.status === 200) {
      await historyMutate();
    } else {
      const body = await resp.json();
      setErrMessage(body.error);
    }
  };

  const getHslColRedToGreen = (decimal) => {
    // Return a CSS HSL string
    return `hsl(${decimal * 100}, 100%, 50%)`;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <PageTitleDescription
        title={"Conversation History"}
        desc={
          "Queries sent to the chatbot are displayed here. If the intent chosen by the chatbot is wrong, you can add the user query as an utterance via the dropdown."
        }
      />
      <TableToolbar
        minimumDate={minimumDate}
        convertSliderPercentToDate={convertSliderPercentToDate}
        setDateRange={setDateRange}
        setConfRange={setConfRange}
        dateRange={dateRange}
        confRange={confRange}
      />
      <TableContainer>
        <Table
          stickyHeader
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={history.length}
          />
          <TableBody>
            {history.slice().map((record, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${record["_id"]}_${record.intent}`}
                  data-testid={"history-table-row"}
                >
                  <TableCell
                    component="th"
                    // id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {new Date(record.datetime + "+0000").toLocaleTimeString()}
                    <br />
                    {new Date(record.datetime + "+0000").toLocaleDateString()}
                  </TableCell>
                  {/* <TableCell style={{wordBreak: "break-word", whiteSpace: "pre"}}>{record["user_query"]}</TableCell> */}
                  <TableCell>
                    {record["user_query"]}
                    <br />
                    <br />
                    <span><RenderedMessage>{record.response}</RenderedMessage></span>
                  </TableCell>
                  <TableCell>{record.intent}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        disableClearable={!record["is_utterance_on_intent"]}
                        sx={{ width: 300 }}
                        options={intents ? [...Object.keys(intents)] : []}
                        onChange={(_, v) => {
                          if (v !== "" && v !== null)
                            addUtteranceToIntent(v, record["user_query"]);
                          else delUtteranceFromIntent(record["user_query"]);
                        }}
                        fullWidth={true}
                        value={record["is_utterance_on_intent"] || null}
                        renderInput={(params) => <TextField {...params} />}
                        data-testid={"history-table-row-select"}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      background: getHslColRedToGreen(record.confidence),
                    }}
                  >
                    {record.confidence.toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
        <FormControlLabel
          control={
            <Switch
              checked={dense}
              onChange={(e) => setDense(e.target.checked)}
            />
          }
          label="Dense padding"
        />
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={historyCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Modal open={errMessage !== ""} onClose={() => setErrMessage("")}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Error!
          </Typography>
          <Typography data-testid="history-table-modal-err" sx={{ mt: 2 }}>
            {errMessage}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

const TableToolbar = (props) => {
  const {
    minimumDate,
    convertSliderPercentToDate,
    setDateRange,
    dateRange,
    setConfRange,
    confRange,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const get1HourSliderDistance = () => {
    const msDiff = new Date().getTime() - new Date(minimumDate).getTime();
    const hourInMs = 60 * 60 * 1000;
    return (100 * hourInMs) / msDiff;
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <div style={{ display: "grid", justifyItems: "end", width: "100%" }}>
        <Tooltip title="Filter list">
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            data-testid={"history-table-filters-btn"}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <CloseIcon
          style={{ float: "right", margin: "5px", cursor: "pointer" }}
          onClick={() => setAnchorEl(null)}
        />
        <div
          style={{
            width: "400px",
            margin: "50px",
            display: "grid",
            gridGap: "20px",
            gridTemplateColumns: "100%",
          }}
        >
          <div>
            <h4>Date Range</h4>
            <p data-testid={"history-filters-from-date"}>
              From:{" "}
              {convertSliderPercentToDate(
                minimumDate,
                dateRange[0],
                new Date()
              ).toLocaleString()}
            </p>
            <Slider
              value={dateRange}
              onChange={(event, newValue, activeThumb) => {
                if (!Array.isArray(newValue)) {
                  return;
                }
                const minDistance = get1HourSliderDistance();

                if (activeThumb === 0) {
                  setDateRange([
                    Math.min(newValue[0], dateRange[1] - minDistance),
                    dateRange[1],
                  ]);
                } else {
                  setDateRange([
                    dateRange[0],
                    Math.max(newValue[1], dateRange[0] + minDistance),
                  ]);
                }
              }}
              disableSwap
            ></Slider>
            <p
              data-testid={"history-filters-to-date"}
              style={{ textAlign: "right" }}
            >
              To:{" "}
              {convertSliderPercentToDate(
                minimumDate,
                dateRange[1],
                new Date()
              ).toLocaleString()}
            </p>
          </div>
          <div>
            <h4>Confidence</h4>
            <p data-testid={"history-filters-from-conf"}>
              From: {confRange[0] / 100}
            </p>
            <Slider
              step={5}
              value={confRange}
              onChange={(event, newValue, activeThumb) => {
                if (!Array.isArray(newValue)) {
                  return;
                }
                const minDistance = 0.1;

                if (activeThumb === 0) {
                  setConfRange([
                    Math.min(newValue[0], confRange[1] - minDistance),
                    confRange[1],
                  ]);
                } else {
                  setConfRange([
                    confRange[0],
                    Math.max(newValue[1], confRange[0] + minDistance),
                  ]);
                }
              }}
              disableSwap
            ></Slider>
            <p
              data-testid={"history-filters-to-conf"}
              style={{ textAlign: "right" }}
            >
              To: {confRange[1] / 100}
            </p>
          </div>
        </div>
      </Popover>
    </Toolbar>
  );
};

function TableHeader(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "datetime",
      numeric: false,
      label: "Time/Date",
      sortable: true
    },
    {
      id: "user_query",
      numeric: false,
      label: "User Query / Response",
      sortable: false
    },

    {
      id: "intent",
      numeric: false,
      label: "Intent",
      sortable: true
    },
    {
      id: "is_utterance_on_intent",
      numeric: true,
      label: "Assign query to intent",
      sortable: false
    },
    {
      id: "confidence",
      numeric: true,
      label: "Conf.",
      sortable: true
    },
  ];

  return (
    <TableHead className={"conversation-table-head"}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ fontWeight: "bold" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
              : <b>{headCell.label}</b>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default HistoryTable;
