import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import "../intents/IntentForm.css";
import { apiRootUrl } from "../../shared/API";
import { STORED_TOKEN } from "../AdminContainer";

const CategoryForm = (props) => {
  const { show, handleClose, handleChange } = props;
  const [category, setCategory] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const cleanForm = () => {
    setCategory("");
    setErrMessage("");
  };

  const submitChangedAdminDetails = async (e) => {
    e.preventDefault();
    const resp = await fetch(`${apiRootUrl}/category/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
      },
      body: JSON.stringify({
        category: category,
      }),
    });
    if (resp.status === 200) {
      cleanForm();
      handleChange();
      handleClose();
    } else {
      const body = await resp.json();
      body.console.error();
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          width: "80vw",
        }}
      >
        <div className={"edit-intent-title"}>
          <h3>Category:</h3>
          <TextField
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            data-testid={"category-name-input"}
            id={"standard-basic"}
            size={"small"}
          />
        </div>
        <div className={"edit-intent-footer"}>
          <p className={"edit-intent-error"} data-testid={"admin-form-err-msg"}>
            {errMessage ? `Error: ${errMessage}` : ""}
          </p>
          <Button
            variant={"contained"}
            id={"intent-form-close"}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant={"contained"}
            onClick={submitChangedAdminDetails}
            data-testid={"category-form-save-btn"}
          >
            {" "}
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CategoryForm;
