import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import "./IntentDeleteWarning.css";

const IntentDeleteWarning = (props) => {
  const { intentName, show, handleClose, handleDelete } = props;

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Warning!
        </Typography>
        <Typography data-testid="history-table-modal-err" sx={{ mt: 2 }}>
          Are you sure you want to delete the '{intentName}' intent?
        </Typography>
        <div className={"intent-delete-warning-container"}>
          <Button
            variant={"contained"}
            onClick={handleClose}
            id={"intent-delete-warning-cancel"}
            style={{ background: "#64748B", color: "#fff" }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"error"}
            onClick={handleDelete}
            data-testid={`intent-${intentName}-del-confirm-btn`}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default IntentDeleteWarning;
