import React from "react";
import {TextField, InputAdornment, Button} from "@mui/material";

const InputWithDeleteBtn = (props) => {
    const {val, setVal, name, textAfterInput} = props
    return (
        <div>
            {val.map((v,idx) => {
                const {text} = v
                return (
                    <div className={"inline-utter-resp"} key={`${name}${idx}`}>
                        <TextField
                            value={text}
                            onChange={e => setVal([...val.slice(0,idx), {text: e.target.value}, ...val.slice(idx+1)])}
                            data-testid={`input-with-del-list-item-${name}-${idx}`}
                            id={"standard-basic"}
                            size={"small"}
                            fullWidth
                            multiline
                            InputProps={{
                                endAdornment: textAfterInput ? <InputAdornment position={"end"}>{textAfterInput}</InputAdornment> : ""
                            }}
                        />
                        <Button
                            onClick={e => setVal([...val.slice(0,idx), ...val.slice(idx+1)])}
                            data-testid={`input-with-del-remove-list-item-${name}-${idx}`}
                            color={"secondary"}
                        >
                            X
                        </Button>
                    </div>
                )
            })}
            <div className={"add-new-container"}>
                <Button
                    onClick={e => {
                        setVal([...val, {text: ""}])
                    }}
                    data-testid={`input-with-del-new-list-item-${name}`}
                    id={`input-with-del-add-new-${name}`}
                >
                    Add new
                </Button>
            </div>
        </div>
    )
}

export default InputWithDeleteBtn;