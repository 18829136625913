import React, {useEffect, useRef, useState} from 'react';

import "./MahirAvatar.css"

const MAHIR_STATES = {
    NORMAL: "NORMAL",
    DO_NOT_LIKE: "DO_NOT_LIKE",
    REALLY_DO_NOT_LIKE: "REALLY_DO_NOT_LIKE"
}

const MahirAvatar = (props) => {
    const [leftEyeRotation, setLeftEyeRotation] = useState(0)
    const [rightEyeRotation, setRightEyeRotation] = useState(0)
    const leftEyeRef = useRef(null)
    const rightEyeRef = useRef(null)
    const mahirImgRef = useRef(null)
    const [mahirState, setMahirState] = useState(MAHIR_STATES.NORMAL)

    useEffect(() => {
        function updatePositionsOfEyes(event) {
            calcEyeRotation(leftEyeRef, setLeftEyeRotation, event)
            calcEyeRotation(rightEyeRef, setRightEyeRotation, event)

            if (mahirState !== MAHIR_STATES.REALLY_DO_NOT_LIKE) {
                const pos = leftEyeRef.current.getBoundingClientRect()
                const x = pos.left + pos.width/2
                const y = pos.top + pos.height/2
                if (Math.abs(x - event.pageX) < 50 && Math.abs(y - event.pageY) < 50) {
                    setMahirState(MAHIR_STATES.DO_NOT_LIKE)
                } else {
                    setMahirState(MAHIR_STATES.NORMAL)
                }
            }
        }

        function calcEyeRotation(ref, setter, event) {
            const pos = ref.current.getBoundingClientRect()
            const x = pos.left + pos.width/2
            const y = pos.top + pos.height/2
            const rad = Math.atan2(event.pageX - x, event.pageY - y);
            const deg = (rad * (180 / Math.PI) * -1) + 180;
            setter(deg)
        }

        window.addEventListener("mousemove", updatePositionsOfEyes)
        return () => window.removeEventListener("mousemove", updatePositionsOfEyes) // return cleanup func
    })

    let mahirImg;
    switch (mahirState) {
        case MAHIR_STATES.DO_NOT_LIKE:
            mahirImg = "/mahir-do-not-like-trim.png";
            break;
        case MAHIR_STATES.REALLY_DO_NOT_LIKE:
            mahirImg = "/mahir-really-do-not-like-trim.png";
            break;
        default:
            mahirImg = "/mahir-trim.png";
    }

    const eyeOpacity = mahirState === MAHIR_STATES.REALLY_DO_NOT_LIKE ? 0 : 1

    const handleMahirClick = () => {
        if (mahirState !== MAHIR_STATES.REALLY_DO_NOT_LIKE) {
            setMahirState(MAHIR_STATES.REALLY_DO_NOT_LIKE)
            setTimeout(() => {
                setMahirState(MAHIR_STATES.NORMAL)
                props.handleClick()
            }, 2000)
        }
    }

    return (
        <div
            className={`mahir-container ${mahirState === MAHIR_STATES.REALLY_DO_NOT_LIKE ? "do-not-like" : ""}`}
        >
            <img className={"mahir-img"}
                 alt={"Avatar of Mahir"}
                 src={mahirImg}
                 ref={mahirImgRef}
                 onClick={handleMahirClick}
            />
            <div
                className='eye'
                ref={leftEyeRef}
                style={{transform: `rotate(${leftEyeRotation}deg)`, opacity: eyeOpacity}}
                onClick={handleMahirClick}
            />
            <div
                className='eye'
                ref={rightEyeRef}
                style={{transform: `rotate(${rightEyeRotation}deg)`, opacity: eyeOpacity}}
                onClick={handleMahirClick}
            />
        </div>
    );
}

export default MahirAvatar;