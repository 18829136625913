import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import "./admin-container.css";
import Navbar from "./Navbar";
import HistoryTable from "./history/HistoryTable";
import IntentList from "./intents/IntentList";
import { useEffect } from "react";
import Login from "./Login";
import { Navigate } from "react-router";
import Email from "./email/Email";
import AdminPage from "./admin-page/AdminPage";

export const HOMEPAGE = "/admin/history";
export const LOGIN = "/admin/login";
export const STORED_TOKEN = "cs2004chatbot_token";

export default function AdminContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== LOGIN && !localStorage.getItem(STORED_TOKEN)) {
      navigate(LOGIN);
    }
  });

  return (
    <>
      <Navbar />
      <div className={"admin-container"}>
        <Routes>
          <Route exact path="/admin-page" element={<AdminPage />} />
          <Route exact path="/history" element={<HistoryTable />} />
          <Route exact path="/intents" element={<IntentList />} />
          <Route exact path="/email" element={<Email />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to={HOMEPAGE} replace />} />
        </Routes>
      </div>
    </>
  );
}
