import Messenger from "./Messenger";
import styles from "./Chat.module.css";
import MahirAvatar from "./MahirAvatar";
import { useState } from "react";

export default function Chat({ sessionId }) {
  const [messages, setMessages] = useState([
    {
      fromBot: true,
      text: "👋 Hi! I'm Mahir-O-Matic. How can I help?",
      datetime: new Date(),
    },
  ]);
  const [isReplying, setIsReplying] = useState(false);

  const typingMs = 1000;

  const handleMahirClick = () => {
    setIsReplying(true);
    setTimeout(() => {
      setMessages([
        ...messages,
        { fromBot: true, text: "That hurts...!", datetime: new Date() },
      ]);
      setIsReplying(false);
    }, typingMs);
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.mahirContainer}>
        <MahirAvatar handleClick={handleMahirClick} />
      </div>
      <Messenger
        messages={messages}
        setMessages={setMessages}
        isReplying={isReplying}
        setIsReplying={setIsReplying}
        typingMs={typingMs}
        sessionId={sessionId}
      />
    </div>
  );
}
