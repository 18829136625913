import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiRootUrl } from "../../shared/API";
import { STORED_TOKEN } from "../AdminContainer";
import AdminForm from "./AdminForm";
import "../intents/Intent.css";
import AdminuserDeleteWarning from "./AdminuserDeleteWarning";

const AdminUsers = (props) => {
  const { user, handleChange } = props;
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => setShowForm(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => setShowDelete(false);

  const deleteUser = async () => {
    const rep = await fetch(
      `${apiRootUrl}/user/${encodeURIComponent(user.username)}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
        },
      }
    );
    if (rep.status === 200) {
      setShowDelete(false);
      handleChange();
    } else {
      const body = await rep.json();
      console.log(body.error);
    }
  };

  return (
    <Fragment key={user.username}>
      <Accordion data-testid={`user-container`} className={`intent-container`}>
        <AccordionSummary
          className={"intent-accordion-summary"}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={`intent-header`}>
            <h3>'{user.username}'</h3>
            <Button
              onClick={(e) => {
                setShowForm(true);
                e.stopPropagation();
              }}
              data-testid={`user-${user.username}-edit-btn`}
            >
              Edit
            </Button>
            <Button
              onClick={(e) => {
                setShowDelete(true);
                e.stopPropagation();
              }}
              data-testid={`user-${user.username}-del-btn`}
            >
              Delete
            </Button>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={"intent-accordion-body"}>
            <div>
              <h4>Email Address</h4>
              {user.email}
            </div>

            <div>
              <h4>Role</h4>
              {user.role}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <AdminForm
        show={showForm}
        handleClose={handleFormClose}
        user={user}
        handleChange={handleChange}
        isNewUser={false}
      />
      <AdminuserDeleteWarning
        show={showDelete}
        handleClose={handleDeleteClose}
        userName={user.username}
        handleDelete={deleteUser}
      />
    </Fragment>
  );
};

export default AdminUsers;
