import React, { useState, Fragment } from "react";
import IntentForm from "./IntentForm";
import { apiRootUrl } from "../../shared/API";
import IntentDeleteWarning from "./IntentDeleteWarning";

import "./Intent.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { STORED_TOKEN } from "../AdminContainer";

const Intent = (props) => {
  const { intent, handleChange } = props;
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => setShowForm(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => setShowDelete(false);

  const deleteIntent = async () => {
    const resp = await fetch(
      `${apiRootUrl}/intent/${encodeURIComponent(intent.text)}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
        },
      }
    );
    if (resp.status === 200) {
      setShowDelete(false);
      handleChange();
    } else {
      const body = await resp.json();
      console.log(body.error);
    }
  };

  const isExpired = intent.expiry && new Date(intent.expiry) < new Date();
  const expiryText = isExpired
    ? "EXPIRED"
    : intent.expiry
    ? `Expires: ${new Date(intent.expiry).toDateString()}`
    : "";

  return (
    <Fragment key={intent.text}>
      <Accordion
        data-testid={`intent-container`}
        className={`intent-container ${isExpired ? "expired" : ""}`}
      >
        <AccordionSummary
          className={"intent-accordion-summary"}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={`intent-header`}>
            <h3>'{intent.text}'</h3>
            <h3>{intent.category}</h3>
            <h4 className={"intent-header-expiry"}>{expiryText}</h4>
            <Button
              onClick={(e) => {
                setShowForm(true);
                e.stopPropagation();
              }}
              data-testid={`intent-${intent.text}-edit-btn`}
            >
              Edit
            </Button>
            <Button
              onClick={(e) => {
                setShowDelete(true);
                e.stopPropagation();
              }}
              data-testid={`intent-${intent.text}-del-btn`}
            >
              Delete
            </Button>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={"intent-accordion-body"}>
            <div>
              <h4>Utterances</h4>
              {intent.utterances?.map((utterance) => (
                <p key={utterance.text}>{utterance.text}</p>
              ))}
            </div>
            <div>
              <h4>Responses</h4>
              {intent.responses?.map((response) => (
                <p key={response.text}>{response.text}</p>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <IntentForm
        show={showForm}
        handleClose={handleFormClose}
        intent={intent}
        handleChange={handleChange}
        isNewIntent={false}
      />
      <IntentDeleteWarning
        show={showDelete}
        handleClose={handleDeleteClose}
        intentName={intent.text}
        handleDelete={deleteIntent}
      />
    </Fragment>
  );
};

export default Intent;
