import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { LOGIN, STORED_TOKEN } from "./AdminContainer";

const navItems = [
  {
    text: "History",
    linkTo: "/admin/history",
  },
  {
    text: "Intents",
    linkTo: "/admin/intents",
  },
  {
    text: "Email",
    linkTo: "/admin/email",
  },
  {
    text: "Chat",
    linkTo: "/chat",
  },
  {
    text: "Admin",
    linkTo: "/admin/admin-page",
  },
];

function Navbar() {
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem(STORED_TOKEN);
  };

  const token_role = () => {
    const token = localStorage.getItem(STORED_TOKEN);
    if (token) {
      try {
        const extracted_role = extract_role(token);
        return extracted_role.role;
      } catch (error) {
        return null;
      }
    }
  };

  const extract_role = (token) => {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid token format");
    }

    const extractRole = JSON.parse(atob(parts[1]));
    return extractRole;
  };

  const role = token_role();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CS2004 Chatbot
          </Typography>
          {localStorage.getItem(STORED_TOKEN) &&
            navItems
              .filter(
                (item) =>
                  role === "admin" || item.linkTo !== "/admin/admin-page"
              )
              .sort((a, b) => a.text.localeCompare(b.text))
              .map((item) => {
                return (
                  <Button
                    color="inherit"
                    component={Link}
                    to={item.linkTo}
                    data-testid={`navbar-${item.text}-btn`}
                    key={item.text}
                    variant={
                      location.pathname === item.linkTo ? "outlined" : ""
                    }
                  >
                    {item.text}
                  </Button>
                );
              })}
          {localStorage.getItem(STORED_TOKEN) && (
            <Button
              onClick={handleLogout}
              component={Link}
              to={LOGIN}
              color="inherit"
              data-testid={"logout-btn"}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
