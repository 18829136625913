import React, {useState, useEffect} from 'react';
import PageTitleDescription from "../../shared/PageTitleDescription";
import InputWithDeleteBtn from "../../shared/InputWithDeleteBtn";
import {Button} from "@mui/material";
import {apiRootUrl} from "../../shared/API";
import {STORED_TOKEN} from "../AdminContainer";

const EmailIntentAlerts = (props) => {
    const {setRequestIsSuccess} = props

    const [emails, setEmails] = useState([""])
    const [daysInAdvance, setDaysInAdvance] = useState([""])
    const [errMessage, setErrMessage] = useState("")

    const submitEmailIntentAlerts = () => {
        setErrMessage("")

        try {
            let daysInAdvanceResp =  daysInAdvance.map(x => +x.text)
            fetch(`${apiRootUrl}/email/intent_alerts`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem(STORED_TOKEN)}` },
                body: JSON.stringify({
                    emails: emails.map(x => x.text),
                    days_in_advance: daysInAdvanceResp
                })
            }).then(async resp => {
                if (resp.status === 200) {
                    setRequestIsSuccess(true)
                } else {
                    const body = await resp.json()
                    setErrMessage(body.error)
                }
            }).catch(e => {
                setErrMessage(e)
            })
        } catch (e) {
            setErrMessage(e)
        }
    }

    useEffect(() => {
        fetch(`${apiRootUrl}/email/intent_alerts`, {
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem(STORED_TOKEN)}` },
        }).then(async resp => {
            if (resp.status === 200) {
                const data = await resp.json()
                if (data.emails.length) setEmails(data.emails)
                if (data['days_in_advance'].length) setDaysInAdvance(data['days_in_advance'].map(x => ({text: x})))
            } else {
                const body = await resp.json()
                setErrMessage(body.error)
            }
        }).catch(e => {
            console.log(e)
            setErrMessage(e)
        })
    }, [])

    return (
        <div>
            <PageTitleDescription
                title={""}
                desc={"This form allows you to send reminder emails (sent at 08:30 UTC) for when an intent is about to expire. Once an intent expires the chatbot will no longer respond with the intent."}
                center
            />
            <div className={"email-form-container"}>
                <h4>Recipient Emails:</h4>
                <InputWithDeleteBtn val={emails} setVal={setEmails} name={"email-intent-alerts-email"} />
                <h4>Sending Schedule:</h4>
                <InputWithDeleteBtn val={daysInAdvance} setVal={setDaysInAdvance} name={"email-intent-alerts-days-in-adv"} textAfterInput={"days before expiry"} />
                <div />
                <div className={"email-form-item-container"}>
                    <Button
                        variant={"contained"}
                        data-testid={"save-email-btn"}
                        onClick={() => submitEmailIntentAlerts()}
                    >
                        Save
                    </Button>
                    <p className={"edit-intent-error"} data-testid={"change-email-form-err-msg"}>{errMessage ? `Error: ${errMessage}` : ""}</p>
                </div>
            </div>
        </div>
    );
}

export default EmailIntentAlerts;