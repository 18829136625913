import styles from "./Messenger.module.css";
import { useRef, useEffect } from "react";
import API from "../shared/API";
import TicTacToeAI from "./games/TicTacToe";
import WhackAMole from "./games/WhackAMole";
import { getUserId } from "../util";
import "@fontsource/inter";
import RenderedMessage from "../components/RenderedMessage";

export default function Messenger({
  messages,
  setMessages,
  isReplying,
  setIsReplying,
  typingMs,
  sessionId,
}) {
  const inputRef = useRef();
  const messageBottom = useRef();

  function askBot(query) {
    setIsReplying(true);
    setTimeout(() => {
      API("/chat", {
        query,
        userId: getUserId(),
        sessionId,
      })
        .then((res) => {
          setMessages((messages) => [
            ...messages,
            {
              fromBot: true,
              text: res.response.text,
              datetime: new Date(),
              source: res.source,
            },
          ]);
          setIsReplying(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }, typingMs);
  }

  function scrollToBottom() {
    messageBottom.current.scrollIntoView();
  }

  useEffect(scrollToBottom, [messages]);

  function handleSubmit(e) {
    // Prevent default form submit
    e.preventDefault();

    // Add user message to list
    const input = inputRef.current.value;

    // Ignore Empty Messages
    if (input === "") {
      return;
    }

    if (input.length > 0) {
      setMessages([
        ...messages,
        {
          fromBot: false,
          text: input,
        },
      ]);
      inputRef.current.value = "";
    }

    askBot(input);
  }

  const pickGame = (message) => {
    const games = [<TicTacToeAI difficulty={"impossible"} />, <WhackAMole />];
    const randDigit = +(message.datetime.getMilliseconds() + "").slice(-1);

    return games[randDigit % games.length];
  };

  return (
    <div className={styles.Messenger} data-testid={"messenger-container"}>
      <div className={styles.messageListOuter}>
        <div className={styles.messageList}>
          <div>
            {messages.map((message, idx) => {
              return (
                <div key={idx}>
                  <ChatMessage
                    fromBot={message.fromBot}
                    source={message.source}
                  >
                    <RenderedMessage>{message.text}</RenderedMessage>
                  </ChatMessage>
                  {message.fromBot && message.text === "Let's play a game!" && (
                    <ChatMessage
                      fromBot={message.fromBot}
                      isGame={true}
                      source={message.source}
                    >
                      <div className={styles.messengerGame}>
                        {pickGame(message)}
                      </div>
                    </ChatMessage>
                  )}
                </div>
              );
            })}

            {isReplying ? (
              <div
                className={[
                  styles.messageListItem,
                  styles.received,
                  styles.writing,
                ].join(" ")}
              >
                <div className={styles.messageListItemContent}>
                  <div className={styles.messageListItemContentText}>
                    <p>...</p>
                  </div>
                </div>
              </div>
            ) : null}

            <div ref={messageBottom}></div>
          </div>
        </div>
      </div>

      <div className={styles.messageInput}>
        <div className={styles.messageInputText}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Type a message..."
              autoFocus
              ref={inputRef}
              data-testid="chat-input"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

let verified = "/green-tick.png";
let verify = "/warning-icon2.png";

const ChatMessage = ({ fromBot, children, isGame, source }) => (
  <div
    className={
      styles.messageListItem + " " + (fromBot ? styles.received : styles.sent)
    }
  >
    <div
      className={styles.messageListItemContent}
      style={{ flexGrow: isGame ? "1" : "0" }}
    >
      <div className={styles.messageListItemContentText}>{children}</div>
    </div>
    {fromBot && source === "database" && (
      <>
        <img
          src={verified}
          alt="verification logo"
          className={styles.verified}
        />
        <span className={styles.popup}>
          The generated message is verified by a staff member
        </span>
      </>
    )}
    {fromBot && source === "openai" && (
      <>
        <img src={verify} alt="verify logo" className={styles.warning} />
        <span className={styles.popup}>
          This generated message is from CHATGPT. Verify it first before using
          it
        </span>
      </>
    )}
  </div>
);
