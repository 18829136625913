import React, {useState} from 'react';

import "./Login.css"
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {apiRootUrl} from "../shared/API";
import {HOMEPAGE, STORED_TOKEN} from "./AdminContainer";
import {useNavigate} from "react-router";

const Login = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errMessage, setErrMessage] = useState("")
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setErrMessage("")
        try {
            const resp = await fetch(`${apiRootUrl}/login`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    username, password
                })
            })
            if (resp.status === 200) {
                const json = await resp.json()
                if (json['token']) {
                    localStorage.setItem(STORED_TOKEN, json['token'])
                    if (!window.history.state || (window.history.state && window.history.state.idx === 0)) {
                        navigate(HOMEPAGE, {replace: true})
                    } else {
                        navigate(-1)
                    }
                } else {
                    setErrMessage("API did not respond with a token!")
                }
            } else {
                const body = await resp.json()
                setErrMessage(body.error)
            }
        } catch (e) {
            setErrMessage(e.toString())
        }
    }

    const handleKeyDown = e => {
        if (e.key === "Enter") {
            handleSubmit()
        }
    }

    return (
        <div className={"login-container"}>
            <div className={"login-controls"} onKeyDown={handleKeyDown}>
                <h3>Login</h3>
                <TextField
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    data-testid={"login-username-input"}
                    id={"standard-basic"}
                    label={"Username"}
                    required
                />
                <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    data-testid={"login-password-input"}
                    id={"standard-basic"}
                    label={"Password"}
                    type={"password"}
                    required
                />
                <p className={"login-error"} data-testid={"login-form-err-msg"}>{errMessage ? `Error: ${errMessage}` : ""}</p>
                <Button
                    onClick={handleSubmit}
                    data-testid={`login-form-submit`}
                    variant={"contained"}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}

export default Login;