import {STORED_TOKEN} from "../admin/AdminContainer";

export const apiRootUrl =
  process.env.REACT_APP_API_ROOT || "http://localhost:5000";

export default async function API(path, params = {}) {
  const res = await fetch(`${apiRootUrl}${path}${convertParamsToQueryString(params)}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem(STORED_TOKEN)}`
    }
  });
  return await res.json();
}

const convertParamsToQueryString = dict => {
  if (Object.entries(dict).length === 0) {
    return ""
  } else {
    return "?" + Object.entries(dict)
        .map(([k,v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&")
  }
}
