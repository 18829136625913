/**
 * @fileoverview Utility functions
 * @description This file contains utility functions that are used throughout the application.
 */

/**
 * Local storage key for user ID
 * @type {String}
 * @constant
 */
export const LOCALSTORAGE_USER_ID_KEY = "USER_ID";

/**
 * Creates a random string of given length
 * @param {Number} length Length of random string to generate
 * @returns
 */
export function randomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

/**
 * Gets the user ID from local storage, or creates a new one if it doesn't exist
 * @returns {String} User ID
 */
export function getUserId() {
  if (!localStorage.getItem(LOCALSTORAGE_USER_ID_KEY)) {
    localStorage.setItem(LOCALSTORAGE_USER_ID_KEY, randomString(32));
  }
  return localStorage.getItem(LOCALSTORAGE_USER_ID_KEY);
}
