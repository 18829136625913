import React from 'react';

const PageTitleDescription = (props) => {
    const {title, desc, center} = props

    return (
        <>
            <h2 style={center ? {textAlign: "center"} : {}}>{title}</h2>
            <p style={center ? {textAlign: "center"} : {}}>{desc}</p>
        </>
    )
}

export default PageTitleDescription;