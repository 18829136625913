import React, { useState } from "react";
import "../admin-page/AdminPage.css";
import { Button } from "@mui/material";
import PageTitleDescription from "../../shared/PageTitleDescription";
import useSWR from "swr";
import API from "../../shared/API";
import AdminForm from "./AdminForm";
import AdminUsers from "./AdminUsers";
import CategoryForm from "./CategoryForm";

export const STORED_TOKEN = "cs2004chatbot_token";

export default function AdminPage() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddForm1, setShowAddForm1] = useState(false);

  const { data, error, mutate } = useSWR("/users", API);

  // const token = localStorage.getItem(STORED_TOKEN);
  // const extract_role = JSON.parse(atob(token.split(".")[1]));
  // console.log(extract_role.role);

  // if (extract_role.role !== "admin") {
  //   return (
  //     <div>You don't have the right permissions to access the admin panel.</div>
  //   );
  // }

  if (error || (data && data.error))
    return (
      <div>
        Failed to load. Tour JWT may have expired. You may need to log out and
        then log back in again.
      </div>
    );

  const handleChange = () => mutate(); // Refetch data stored in database if we make a change

  return (
    <section>
      <PageTitleDescription
        title={"Admin Page"}
        desc={
          "This panel is designed for the admin to carry out administrative activities such as easily addind users, adding, removing, and editing roles, as well as organizing the intents by adding categories ensuring precise control over data, user access and permissions."
        }
      />
      <div className={"add-new-category-btn-container"}>
        <Button
          variant={"contained"}
          data-testid={"new-category-btn"}
          onClick={() => setShowAddForm(true)}
        >
          Add new Category
        </Button>
        <Button
          variant={"contained"}
          data-testid={"new-user-btn"}
          onClick={() => setShowAddForm1(true)}
        >
          Add new User
        </Button>
      </div>
      <CategoryForm
        show={showAddForm}
        handleClose={() => {
          setShowAddForm(false);
        }}
        handleChange={handleChange}
        isNewCategory={true}
      />
      <AdminForm
        show={showAddForm1}
        handleClose={() => {
          setShowAddForm1(false);
        }}
        handleChange={handleChange}
        isNewUser={true}
      />
      <div className={"intent-list-list-container"}>
        {data &&
          Object.entries(data).map(([username, user]) => (
            <AdminUsers
              user={user}
              handleChange={handleChange}
              key={username}
            />
          ))}
      </div>
    </section>
  );
}
