import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import "./IntentForm.css";
import { apiRootUrl } from "../../shared/API";
import { Button, FormControl, Popover, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { STORED_TOKEN } from "../AdminContainer";
import InputWithDeleteBtn from "../../shared/InputWithDeleteBtn";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { HelpOutline } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import useSWR from "swr";
import API from "../../shared/API";

import Autocomplete from "@mui/material/Autocomplete";

const IntentForm = (props) => {
  const { intent, show, handleClose, handleChange, isNewIntent } = props;

  const intentText = intent && intent.text;
  const [title, setTitle] = useState(intentText);
  const intentExpiry = intent && intent.expiry;
  const intentUtterances = intent?.utterances ?? [];
  const intentResponses = intent?.responses ?? [];
  const [utterances, setUtterances] = useState([...intentUtterances]);
  const [responses, setResponses] = useState([...intentResponses]);
  const [errMessage, setErrMessage] = useState("");
  const [expiry, setExpiry] = useState(new Date(intentExpiry));
  const [addExpiry, setAddExpiry] = useState(Boolean(expiry));

  useEffect(() => {
    const newUtterancesBtn = document.getElementById(
      "intent-form-add-new-utterances"
    );
    const newResponsesBtn = document.getElementById(
      "intent-form-add-new-responses"
    );
    if (!newUtterancesBtn || !newResponsesBtn) {
      return;
    }
    if (
      newUtterancesBtn.getBoundingClientRect().y >=
      newResponsesBtn.getBoundingClientRect().y
    ) {
      newUtterancesBtn.scrollIntoView();
    } else {
      newResponsesBtn.scrollIntoView();
    }
  }, [utterances, responses]);

  useEffect(() => {
    setUtterances([...intentUtterances]);
    setResponses([...intentResponses]);
    setTitle(intentText);
    if (intentExpiry) {
      setExpiry(new Date(intent.expiry));
      setAddExpiry(true);
    } else {
      setExpiry(new Date());
      setAddExpiry(false);
    }
  }, [show, intent]);

  const submitChangedIntent = async () => {
    setErrMessage("");
    const url = isNewIntent
      ? `${apiRootUrl}/intent`
      : `${apiRootUrl}/intent/${encodeURIComponent(intent.text)}`;
    const resp = await fetch(url, {
      method: isNewIntent ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
      },
      body: JSON.stringify({
        text: title,
        utterances,
        responses,
        expiry: addExpiry ? expiry.toDateString() : null,
        // add category to the db
        category: selectCategory,
      }),
    });
    if (resp.status === 200) {
      cleanForm();
      handleChange();
      handleClose();
    } else {
      const body = await resp.json();
      setErrMessage(body.error);
    }
  };

  const cleanForm = () => {
    setTitle("");
    setUtterances([]);
    setResponses([]);
    setErrMessage("");
  };

  const { data: categories } = useSWR("/categories", API);
  const intentCategory = intent && intent.category;
  const [selectCategory, setSelectCategory] = useState(intentCategory);

  const handleCategoryChange = (event, newValue) => {
    setSelectCategory(newValue);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          width: "80vw",
        }}
      >
        <div className={"edit-intent-title"}>
          <h3>Intent Name:</h3>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            data-testid={"intent-name-input"}
            id={"standard-basic"}
            size={"small"}
          />
          <h3>Category:</h3>
          <Autocomplete
            disablePortal
            sx={{ width: 300 }}
            options={categories ? Object.keys(categories) : []}
            fullWidth={true}
            value={selectCategory}
            onChange={handleCategoryChange}
            data-testid={"intent-category-select"}
            renderInput={(params) => <TextField {...params} label="category" />}
          />

          <div
            className={"edit-intent-expiry"}
            data-testid={"edit-intent-form-expiry"}
          >
            <ExpiryPicker
              expiry={expiry}
              setExpiry={setExpiry}
              addExpiry={addExpiry}
              setAddExpiry={setAddExpiry}
            />
          </div>
        </div>
        <div className={"edit-intent-body"}>
          <div>
            <h4>Utterances:</h4>
            <InputWithDeleteBtn
              val={utterances}
              setVal={setUtterances}
              name={"utterances"}
            />
          </div>
          <div>
            <h4>Responses:</h4>
            <InputWithDeleteBtn
              val={responses}
              setVal={setResponses}
              name={"responses"}
            />
          </div>
        </div>
        <div className={"edit-intent-footer"}>
          <p
            className={"edit-intent-error"}
            data-testid={"intent-form-err-msg"}
          >
            {errMessage ? `Error: ${errMessage}` : ""}
          </p>
          <Button
            variant={"contained"}
            id={"intent-form-close"}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant={"contained"}
            onClick={submitChangedIntent}
            data-testid={"intent-form-save-btn"}
          >
            {" "}
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const ExpiryPicker = (props) => {
  const { expiry, setExpiry, addExpiry, setAddExpiry } = props;

  const [expiryHelpAnchor, setExpiryHelpAnchor] = useState(null);

  if (addExpiry) {
    return (
      <>
        <div className={"edit-intent-expiry-help"}>
          <HelpOutline
            onMouseEnter={(e) => {
              setExpiryHelpAnchor(e.currentTarget);
            }}
            onMouseLeave={() => setExpiryHelpAnchor(null)}
          />
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={!!expiryHelpAnchor}
            anchorEl={expiryHelpAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => setExpiryHelpAnchor(null)}
            disableRestoreFocus
          >
            <div className={"edit-intent-expiry-help-msg"}>
              <Typography>Set an expiry date for this Intent</Typography>
              <Typography>
                When the expiry date passes, the Chatbot will no longer retrain
                using the intent.
              </Typography>
              <Typography>
                Email alerts for expiries can be configured within the Email
                tab.
              </Typography>
            </div>
          </Popover>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={"Intent Expiry"}
            value={expiry}
            onChange={(d) => {
              const date = new Date(d);
              date.setHours(12); // Put hour to middle of the day to avoid timezone problems. Only the date will be looked at on the backend.
              setExpiry(date);
            }}
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
            inputFormat="dd-MM-yyyy"
          />
        </LocalizationProvider>
        <Button
          onClick={(e) => {
            setAddExpiry(false);
          }}
          data-testid={`edit-intent-form-remove-expiry`}
          color={"secondary"}
        >
          X
        </Button>
      </>
    );
  } else {
    return (
      <Button
        onClick={() => {
          setAddExpiry(true);
          if (expiry == null) {
            setExpiry(new Date());
          }
        }}
        data-testid={`edit-intent-expiry-add-btn`}
      >
        Add Intent Expiry Date
      </Button>
    );
  }
};

export default IntentForm;
