import { lazy, Suspense } from "react";
import Chat from "./chat/Chat";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { Navigate } from "react-router";
import { useState } from "react";
import { randomString } from "./util";

const AdminContainer = lazy(() => import("./admin/AdminContainer"));

function App() {
  const [sessionId] = useState(randomString(32));

  return (
    <Routes>
      <Route exact path="/" element={<Chat sessionId={sessionId} />} />
      <Route
        path="/admin/*"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <AdminContainer />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
