import React, {useState} from 'react';
import {Modal, Tab, Tabs} from "@mui/material";
import PageTitleDescription from "../../shared/PageTitleDescription";
import EmailLowConf from "./EmailLowConf";
import EmailIntentAlerts from "./EmailIntentAlerts";

import "./Email.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Email = (props) => {
    const [tab, setTab] = useState(0)
    const [requestIsSuccess, setRequestIsSuccess] = useState(false)

    return (
        <section>
            <PageTitleDescription
                title={"Email Schedule"}
                desc={""}
            />
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} centered >
                <Tab label={"Low Confidence Queries"} className={"email-tab-btn"} />
                <Tab label={"Expiring Intent Alerts"} className={"email-tab-btn"} />
            </Tabs>
            <div className={"email-tab-content-container"}>
                {tab === 0 && <EmailLowConf setRequestIsSuccess={setRequestIsSuccess} />}
                {tab === 1 && <EmailIntentAlerts setRequestIsSuccess={setRequestIsSuccess} />}
            </div>
            <Modal
                open={requestIsSuccess}
                onClose={() => setRequestIsSuccess(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" component="h2">
                        Success!
                    </Typography>
                </Box>
            </Modal>
        </section>
);
}

export default Email;