export const convertDateToSliderPerc = (earliestDate, date, latestDate) => {
    const totalDiff = latestDate.getTime() - earliestDate.getTime()
    const dateDiff = date.getTime() - earliestDate.getTime()
    if (dateDiff < 0) {
        return 0;
    }
    return Math.min(100, 100*dateDiff/totalDiff)
}

export const convertSliderPercentToDate = (earliestDate, perc, latestDate) => {
    perc = Math.min(100, Math.max(0, perc))
    const msDiff = latestDate.getTime() - new Date(earliestDate).getTime()
    return new Date(new Date(earliestDate).getTime() + msDiff * perc/100)
}