import React, {useEffect, useState} from 'react';
import {Button, Slider} from "@mui/material";
import InputWithDeleteBtn from "../../shared/InputWithDeleteBtn";
import {apiRootUrl} from "../../shared/API";
import {STORED_TOKEN} from "../AdminContainer";
import Cron from "react-js-cron-mui";
import PageTitleDescription from "../../shared/PageTitleDescription";

import "./EmailLowConf.css"

const EmailLowConf = (props) => {
    const {setRequestIsSuccess} = props

    const [emails, setEmails] = useState([""])
    const [confThreshold, setConfThreshold] = useState(0.8)
    const [cron, setCron] = useState("30 9 * * 1")
    const [errMessage, setErrMessage] = useState("")

    useEffect(() => {
        fetch(`${apiRootUrl}/email/low_confidence`, {
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem(STORED_TOKEN)}` },
        }).then(async resp => {
            if (resp.status === 200) {
                const data = await resp.json()
                if (data.emails.length) setEmails(data.emails)
                setConfThreshold(data['confidence_threshold'])
                if (data.cron) setCron(data.cron)
            } else {
                const body = await resp.json()
                setErrMessage(body.error)
            }
        }).catch(e => {
            console.log(e)
            setErrMessage(e)
        })
    }, [])


    const submitChangeEmailSettings = async () => {
        setErrMessage("")
        fetch(`${apiRootUrl}/email/low_confidence`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem(STORED_TOKEN)}` },
            body: JSON.stringify({
                emails: emails.map(x => x.text),
                confidence_threshold: confThreshold,
                cron
            })
        }).then(async resp => {
            if (resp.status === 200) {
                setRequestIsSuccess(true)
            } else {
                const body = await resp.json()
                setErrMessage(body.error)
            }
        }).catch(e => {
            setErrMessage(e)
        })
    }

    return (
        <div>
            <PageTitleDescription
                title={""}
                desc={"This form allows you to schedule emails where the intent chosen by the chatbot has a confidence lower than a set threshold. The email will contain only new queries since the last email was sent."}
                center
            />
            <div className={"email-form-container"}>
                <h4>Recipient Emails:</h4>
                <InputWithDeleteBtn val={emails} setVal={setEmails} name={"email"} />
                <h4>Confidence threshold:</h4>
                <div className={"email-form-item-container"}>
                    <p data-testid={"email-form-confidence-threshold"}>{confThreshold.toFixed(2)}</p>
                    <Slider
                        aria-label="Small steps"
                        value={confThreshold}
                        onChange={(e,newValue) => setConfThreshold(newValue)}
                        step={0.05}
                        marks
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                    />
                </div>
                <h4>Sending schedule (UTC timezone):</h4>
                <div className={"email-form-item-container"}>
                    <Cron
                        value={cron}
                        setValue={setCron}
                    />
                </div>
                <div />
                <div className={"email-form-item-container"}>
                    <Button
                        variant={"contained"}
                        data-testid={"save-email-btn"}
                        onClick={() => submitChangeEmailSettings()}
                    >
                        Save
                    </Button>
                    <p className={"edit-intent-error"} data-testid={"change-email-form-err-msg"}>{errMessage ? `Error: ${errMessage}` : ""}</p>
                </div>
            </div>
        </div>
    );
}

export default EmailLowConf;