import { Autocomplete, Box, Button, Modal, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../intents/IntentForm.css";
import { apiRootUrl } from "../../shared/API";
import { STORED_TOKEN } from "../AdminContainer";

const AdminForm = (props) => {
  const { user, show, handleClose, handleChange, isNewUser } = props;
  const userName = user && user.username;
  const emailAddress = user && user.email;
  const userPassword = user && user.password;
  const userRole = user && user.role;
  const [inputValue, setInputValue] = useState("");
  const [username, setUsername] = useState(userName);
  const [email, setEmail] = useState(emailAddress);
  const [password, setPassword] = useState(userPassword);
  const [role, setRole] = useState(userRole);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    setUsername(userName);
    setEmail(emailAddress);
    setPassword(userPassword);
    setRole(userRole);
  }, [show, user]);

  const submitChangedAdminDetails = async (e) => {
    e.preventDefault();

    const url = isNewUser
      ? `${apiRootUrl}/user`
      : `${apiRootUrl}/user/${encodeURIComponent(user.username)}`;
    const response = await fetch(url, {
      method: isNewUser ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(STORED_TOKEN)}`,
      },
      body: JSON.stringify({
        username: username,
        email: email,
        password: password,
        role: role,
      }),
    });
    if (response.status === 200) {
      cleanForm();
      handleChange();
      handleClose();
    } else {
      const body = await response.json();
      setErrMessage(body.error);
    }
  };

  const cleanForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setRole("");
    setErrMessage("");
  };

  const roles = ["admin", "module leader"];

  const handleRoleChange = (event, newValue) => {
    setRole(newValue);
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "100vh",
          width: "80vw",
        }}
      >
        <div className={"edit-intent-title"}>
          <h3>Module leader:</h3>
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            data-testid={"module_leader-name-input"}
            id={"standard-basic"}
            size={"small"}
          />
          <h3>Email Address:</h3>
          <TextField
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            data-testid={"module_leader-email-input"}
            id={"standard-basic"}
            size={"small"}
          />
          <h3>Password:</h3>
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            data-testid={"module_leader-password-input"}
            id={"standard-basic"}
            size={"small"}
          />
          <h3>Role:</h3>
          <Autocomplete
            disablePortal
            sx={{ width: 210 }}
            options={roles}
            value={role}
            onChange={handleRoleChange}
            fullWidth={true}
            renderInput={(params) => (
              <TextField {...params} label="role" size="small" />
            )}
          />
        </div>
        <div className={"edit-intent-footer"}>
          <p className={"edit-intent-error"} data-testid={"admin-form-err-msg"}>
            {errMessage ? `Error: ${errMessage}` : ""}
          </p>
          <Button
            variant={"contained"}
            id={"intent-form-close"}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant={"contained"}
            onClick={submitChangedAdminDetails}
            data-testid={"admin-form-save-btn"}
          >
            {" "}
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AdminForm;
