import useSWR from "swr";
import API from "../../shared/API";
import Intent from "./Intent";
import IntentForm from "./IntentForm";
import { useState } from "react";
import { Button } from "@mui/material";

import "./IntentList.css";
import PageTitleDescription from "../../shared/PageTitleDescription";

export default function IntentList() {
  const [showAddForm, setShowAddForm] = useState(false);

  const { data, error, mutate } = useSWR("/intents", API);

  if (error || (data && data.error))
    return (
      <div>
        Failed to load. Your JWT may have expired. You may need to log out and
        then log back in again.
      </div>
    );
  if (!data) return <div>loading...</div>;

  const handleChange = () => mutate(); // Refetch data stored in database if we make a change

  const baseIntent = {
    text: "",
    utterances: [],
    responses: [],
  };

  const sorted_data = Object.values(data).filter(i => i?.category !== undefined).sort((a, b) => a.category.localeCompare(b.category));

  return (
    <section>
      <PageTitleDescription
        title={"Intents"}
        desc={
          "An intent must have at least 1 utterance and response, and these cannot be duplicated across intents. The more utterances for an intent, the more accurate the model will be - 5 or more utterances is recommended for each intent. Finally, the chatbot will retrain each night, so changes made in this GUI will be present the day after changes are made."
        }
      />
      <div className={"add-new-intent-btn-container"}>
        <Button
          variant={"contained"}
          data-testid={"new-intent-btn"}
          onClick={() => setShowAddForm(true)}
        >
          Add new Intent
        </Button>
      </div>
      <IntentForm
        show={showAddForm}
        handleClose={() => {
          setShowAddForm(false);
        }}
        handleChange={handleChange}
        intent={baseIntent}
        isNewIntent={true}
      />
      <div className={"intent-list-list-container"}>
        {sorted_data.map((intent) => (
          <Intent
            intent={intent}
            handleChange={handleChange}
            key={intent.text}
          />
        ))}
      </div>
    </section>
  );
}
