import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import "highlight.js/styles/atom-one-dark.css";

/**
 * Renders a message using ReactMarkdown and adds syntax highlighting
 * @param {Object} props
 * @param {string} props.children
 * @returns {JSX.Element}
 */
export default function RenderedMessage({ children }) {
  return (
    <ReactMarkdown
      rehypePlugins={[[rehypeHighlight, { detect: true }]]}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
}
